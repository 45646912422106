<template>
  <div class="py-5 text-center text-md-start">
    <h1 class="title text-center text-capitalize">
      <div v-if="this.$store.getters.lan">Apply for GST</div>
      <div v-else>申请GST</div>
    </h1>
    <div class="title fs-5">
      <div v-if="this.$store.getters.lan">
        <p>
          Most businesses in Canada must pay goods and services tax and compete
          sales tax (GST/HST) registration, except for those businesses that are
          considered as small suppliers.
        </p>
        <p>
          If your company sold or provided taxable goods or services in Canada
          in the past year, and the taxable income exceeded $30,000, the CRA
          requires the company to register for a GST/HST account, collect
          GST/HST from customers, and pay the collected GST/HST to the CRA at
          different time periods such as quarterly or annually. If your
          company’s taxable income is less than $30,000, then your company is a
          small supplier and you can choose to register or not to register for a
          GST/HST account.
        </p>
        <p>
          Once this account is registered, it is the responsibility to collect
          GST/HST when selling goods or providing services in Canada (except for
          goods or services with zero tax rate), and finally return the
          collected GST/HST to the CRA. If you are a taxi driver, you must
          register for a GST/HST account.
        </p>
        <p>
          After registering a GST/HST account, in addition to collecting the
          GST/HST of the service object, your company can also deduct the
          GST/HST paid in business expenses, which is the tax deduction (ITC).
          For goods and services with zero tax rate, you do not need to collect
          GST/HST from customers, but you can refund the HST paid. If your
          business is providing tax-free goods or services, you do not need to
          register for a GST/HST account, because you do not need to collect
          GST/HST from your customers, and you do not need to apply for a refund
          of the GST/HST paid.
        </p>
      </div>
      <div v-else>
        <p>
          大多数在加拿大的企业都必须缴纳商品和服务税和统一销售税（GST / HST）注册， 但那些被视为小型供应商的企业除外。
        </p>
        <p>
          如果在过去的一年您的公司在加拿大销售或提供应课税商品或服务, 且应税收入超过$30,000, 税务局要求企业注册GST/HST帐户，代收客人的GST/HST, 并按照季度，年度等不同的时间段将代收的GST/HST缴纳给税务局。如果您公司的应税收入不到$30,000，那么您的公司属于小供应商范畴，可以选择注册或者不注册GST/HST帐户。一旦注册了这个帐户，就有责任在加拿大销售商品或提供服务的时候收取GST/HST (零税率的商品或服务除外)，并最终将代收的GST/HST返还给税局。如果您是出租车司机，就一定要注册GST/HST帐户。
        </p>
        <p>
          在注册了GST/HST帐户之后，除了要代收服务对象的GST/HST，您的企业也可以将生意费用中付出的GST/HST扣回,也就是税务上讲得消费税减免额（ITC）。对于零税率的商品和服务，您无需收客人的GST/HST，但可以将所付的HST退回来。如果您经营的是免税商品或服务，就不需要注册GST/HST帐户，因为您无需收客人的GST/HST，也就不需要申请退回所付的GST/HST。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>